import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { LoginComponent } from './Components/login.component';
import { MinimalApiTestComponent } from './Components/minimal-api-test/minimal-api-test.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { HomeDashboardComponent } from './Dashboards/home-dashboard/home-dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: 'trading', pathMatch: 'full' },
  {
    path: 'home-dashboard',
    component: HomeDashboardComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'timeseries',
    canActivate: [MsalGuard],
    loadChildren: () => import('./modules/timeserie/timeserie.module').then((m) => m.TimeserieModule),
  },
  { path: 'login', component: LoginComponent, canActivate: [MsalGuard] },
  {
    path: 'not-found',
    component: NotFoundComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'trading',
    canActivate: [MsalGuard],
    loadChildren: () => import('./modules/trading/trading.module').then((m) => m.TradingModule),
  },
  {
    path: 'minimalapitest',
    component: MinimalApiTestComponent,
  },
  {
    // default -> if not found, then...
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
