import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ErrorService } from './error.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorService) {}

  // when using a catchError operator in a service, returning a default value, this behavior (throwing an error via (global)errorhandler) will be skipped.
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          // handle HTTP errors
          switch (error.status) {
            case 401:
              this.errorService.addError('Het token is verlopen herlaad de pagina');
              break;
            case 403:
              this.errorService.addError('Geen rechten voor deze actie');
              break;
          }
        }
        return throwError(new Error(error.message));
      }),
    );
  }
}
