<div id="main">
  <!-- Navbar-top -->
  <div class="navbar-top" style="display: flex; justify-content: space-between; align-items: row; align-items: center;">
    <div>
      <span *ngIf="!productionEnvironment" class="environment-label">Staging</span>
    </div>

    <div (click)="refresh()" class="refresh-warning" *ngIf="suggestRefresh">
      <div class="refresh-button">
        <mat-icon class="icon-refresh">refresh</mat-icon>
      </div>
      <div class="refresh">
        Ververs om op de huidige pagina tenant te wijzigen.
      </div>
    </div>

    <div *ngIf="!productionEnvironment">
      <label class="container" for="customControlValidation2">staging backend
        <input type="checkbox" id="customControlValidation2" [value]="staging" [checked]="staging.checked"
          (change)="environmentChanged(staging)" required />
        <span class="checkmark"></span>
      </label>
      <label class="container" for="customControlValidation3">local backend
        <input type="checkbox" id="customControlValidation3" [value]="local" [checked]="local.checked"
          (change)="environmentChanged(local)" required />
        <span class="checkmark"></span>
      </label>
    </div>

    <div *ngIf="!productionEnvironment" style="display: flex; flex-direction: row; padding: 1em">

      <button class="btn-nieuwestroom " (click)="copyToken('api')">
        Copy Operations API token
      </button>

      <button class="btn-nieuwestroom " (click)="copyToken('minimalapi')">
        Copy Minimal API token
      </button>
      <input id="token" type="text" style="display: none" />
    </div>

    <div>
      <mat-icon class="notify-button" *ngIf="errorService.getAllErrors().length > 0"
        (click)="toggleNotificationCenter()"><span class="notify-icon">notifications</span></mat-icon>
    </div>

  </div>

  <!-- notification center (errors) -->
  <ng-container *ngIf="showNotificationCenter">
    <app-notification-center (closeNotificationCenter)="closeNotificationCenter()"
      [errors]="errorService.getAllErrors()">
    </app-notification-center>
  </ng-container>

  <!-- Content -->
  <div>
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>

  <!-- Login options, for dev use  -->
  <div style="display: none">
    <button mat-raised-button [matMenuTriggerFor]="loginMenu" *ngIf="!loginDisplay">
      Login
    </button>
    <mat-menu #loginMenu="matMenu">
      <button mat-menu-item (click)="loginRedirect()">
        Login using Redirect
      </button>
      <button mat-menu-item (click)="loginPopup()">Login using Popup</button>
    </mat-menu>

    <button mat-raised-button [matMenuTriggerFor]="logoutMenu" *ngIf="loginDisplay">
      Logout
    </button>
    <mat-menu #logoutMenu="matMenu">
      <button mat-menu-item (click)="logout()">Logout using Redirect</button>
      <button mat-menu-item (click)="logout(true)">Logout using Popup</button>
    </mat-menu>
  </div>
</div>