import { take, tap } from 'rxjs/operators';
import { DealsService } from 'src/app/modules/trading-api/api/deals.service';
import { DealResponse } from 'src/app/modules/trading-api/model/dealResponse';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-minimal-api-test',
  templateUrl: './minimal-api-test.component.html',
  styleUrls: ['./minimal-api-test.component.scss'],
})
export class MinimalApiTestComponent implements OnInit {
  deals?: DealResponse[] = null;

  constructor(private tradingDealsService: DealsService) {}

  ngOnInit(): void {
    this.tradingDealsService
      .dealsGetDeals(
        false,
        false,
        10,
        0,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        false,
      )
      .pipe(
        take(1),
        tap((response) => (this.deals = response)),
        tap((response) => console.log(response)),
      )
      .subscribe();
  }
}
