import { MsalBasedAuthorizationService } from 'src/app/services/msal-based-authorization.service';
import { NotificationService } from 'src/app/services/notification.service';

import { Component, OnInit } from '@angular/core';
import { environment } from '@environment';

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.scss'],
})
export class HomeDashboardComponent implements OnInit {
  local: any = false;
  staging: any = false;
  production: boolean = environment.production;

  sections;

  constructor(private msalBasedAuthorizationService: MsalBasedAuthorizationService, private notificationService: NotificationService) {
    const isLocal = localStorage['target-enviroment'] === 'local';

    this.local = { checked: isLocal, name: 'local' };
    this.staging = { checked: !isLocal, name: 'staging' };
  }

  ngOnInit() {
    this.sections = this.msalBasedAuthorizationService.allowedSections();
  }

  copyToken(type?: 'api' | 'minimalapi') {
    const ie = document.getElementById('token') as HTMLInputElement;
    ie.value =
      type === 'minimalapi'
        ? this.msalBasedAuthorizationService.getMinimalApiMsalToken()
        : `Bearer ${this.msalBasedAuthorizationService.getOperationsApiMsalToken()}`;
    ie.style.display = 'block';
    ie.select();
    document.execCommand('copy');
    ie.style.display = 'none';
    this.notificationService.showSuccess('Copied!');
  }

  environmentChanged(entry: any) {
    // TODO ID, can be simple formcontrols
    if (entry.name === 'staging') {
      localStorage['target-enviroment'] = 'staging';
    } else {
      localStorage['target-enviroment'] = 'local';
    }
    location.reload();
  }
}
