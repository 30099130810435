<div class="dashboard-content">

  <div class="row">

    <div class="col-sm-6">
      <div class="card dashboard-card">
        <div class="card-header dashboard-card-title">Omgeving</div>
        <div style="display: flex; flex-direction: row; align-items: center;">
          <div style="display: flex; flex-direction: column; padding: 1em;">
            <!-- Environments -->
            <div class="navbar-environments">
              <label class="container" for="customControlValidation2">staging backend
                <input type="checkbox" id="customControlValidation2" [value]="staging" [checked]="staging.checked"
                  (change)="environmentChanged(staging)" required />
                <span class="checkmark"></span>
              </label>
              <label class="container" for="customControlValidation3">local backend
                <input type="checkbox" id="customControlValidation3" [value]="local" [checked]="local.checked"
                  (change)="environmentChanged(local)" required />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; padding: 1em">
            <button class="btn-nieuwestroom " (click)="copyToken('api')">
              Copy Operations API token
            </button>
            <button class="btn-nieuwestroom " (click)="copyToken('minimalapi')">
              Copy Minimal API token
            </button>
            <input id="token" type="text" style="display: none" />
          </div>

        </div>
      </div>
    </div>
  </div>
</div>