export interface MainDashBoardSection {
  name: string;
  icon: string;
  path: string;
}

export const sectionsGeneric: MainDashBoardSection[] = [
  {
    name: 'Startpagina',
    icon: 'home',
    path: 'home-dashboard',
  },
];

export const sectionsTrading: MainDashBoardSection[] = [
  {
    name: 'Trading',
    icon: 'work',
    path: 'trading',
  },
];

export const sectionsOnboarding: MainDashBoardSection[] = [
  //  Will be visible when MinimalAPI rights are added to the group the person is in.y
  //   {
  //     name: 'Activatieproces (Beta)',
  //     icon: 'text_snippet',
  //     path: 'contract-state-transitioning',
  //   },
];

export const sectionsSupplying: MainDashBoardSection[] = [];

// For feature toggles / Staging sections such as styling guide
export const sectionsStaging: MainDashBoardSection[] = [];
