import { Settings as LuxonSettings } from 'luxon';
import moment from 'moment-timezone';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Location, registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalCustomNavigationClient, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { environment } from '@environment';

import { DEFAULT_TIMEZONE } from './constants';
import { MainDashBoardSection } from './Constants/main-dashboard-sections.constants';
import { ErrorService } from './services/error.service';
import { MsalBasedAuthorizationService } from './services/msal-based-authorization.service';
import { NotificationService } from './services/notification.service';

registerLocaleData(localeNl);
LuxonSettings.defaultLocale = 'nl';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  connectionError: string;
  production: boolean = environment.production;
  toggleNavbar = false;
  showNavtext = true;
  document: any;
  form: UntypedFormGroup;
  name: string;
  suggestRefresh = false;
  tenantSelected: any; // bouwfout, wel in html niet in ts.
  isIframe = false;
  loginDisplay = false;
  showNotificationCenter = false;

  sections: MainDashBoardSection[];

  local: any = false;
  staging: any = false;

  productionEnvironment: boolean = environment.production;

  requiredServicesInitialized = false;

  authorizationAndRights$: Observable<any>;

  private connectionTimeout: any;

  private readonly destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private msalBasedAuthorizationService: MsalBasedAuthorizationService,
    private router: Router,
    private location: Location,
    private fb: UntypedFormBuilder,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private adapter: DateAdapter<any>,
    private notification: NotificationService,
    public errorService: ErrorService,
  ) {
    // Custom navigation set for client-side navigation. See performance doc for details: https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular/docs/v2-docs/performance.md
    const customNavigationClient = new MsalCustomNavigationClient(authService, this.router, this.location);
    this.authService.instance.setNavigationClient(customNavigationClient);
    this.adapter.setLocale('nl');
  }

  ngOnInit(): void {
    const isLocal = localStorage['target-enviroment'] === 'local';

    this.local = { checked: isLocal, name: 'local' };
    this.staging = { checked: !isLocal, name: 'staging' };

    this.init();

    // Msal trigger and token saver
    this.authorizationAndRights$ = this.msalBasedAuthorizationService.authorizationListener$.pipe(takeUntil(this.destroying$)).subscribe();
    this.msalBasedAuthorizationService.initializeMinimalApis();

    // Build sections based on Microsoft Authorization and groups
    this.sections = this.msalBasedAuthorizationService.allowedSections();
  }

  init = async () => {
    this.isIframe = window !== window.parent && !window.opener;

    this.setLoginDisplay();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$),
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    // DEFAULT Timezone is declared hardcoded here
    moment.tz.setDefault(DEFAULT_TIMEZONE);

    this.form = this.fb.group({
      name: this.fb.array([]),
    });
  };

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest).subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
      });
    } else {
      this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
      });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this.destroying$.next(null);
    this.destroying$.complete();

    if (this.connectionTimeout) {
      clearTimeout(this.connectionTimeout);
    }
  }

  refresh() {
    window.location.reload();
  }

  copyToken(type?: 'api' | 'minimalapi') {
    const ie = document.getElementById('token') as HTMLInputElement;
    ie.value =
      type === 'minimalapi'
        ? this.msalBasedAuthorizationService.getMinimalApiMsalToken()
        : `Bearer ${this.msalBasedAuthorizationService.getOperationsApiMsalToken()}`;
    ie.style.display = 'block';
    ie.select();
    document.execCommand('copy');
    ie.style.display = 'none';
    this.notification.showSuccess('Copied!');
  }

  environmentChanged(entry: any) {
    // TODO ID, can be simple formcontrols
    if (entry.name === 'staging') {
      localStorage['target-enviroment'] = 'staging';
    } else {
      localStorage['target-enviroment'] = 'local';
    }
    location.reload();
  }

  getErrormessages() {
    return this.errorService.getRecentErrors().length > 0;
  }

  toggleNotificationCenter(show?: boolean) {
    this.showNotificationCenter = !this.showNotificationCenter;
  }

  closeNotificationCenter() {
    this.showNotificationCenter = false;
  }

  openWithTemplate(tpl: TemplateRef<any>) {
    const config = new OverlayConfig({
      height: '250px',
      width: '700px',
      hasBackdrop: false,
      backdropClass: 'cdk-overlay-dark-backdrop',
      positionStrategy: this.overlay.position().global().centerVertically().centerHorizontally(),
    });
    const overlayRef = this.overlay.create(config);
    overlayRef.attach(new TemplatePortal(tpl, this.viewContainerRef));
    overlayRef.backdropClick().subscribe(() => overlayRef.dispose());
  }

  minimizeSideNav = () => {
    if (this.showNavtext === false) {
      document.getElementById('sidenav').style.width = '250px';
      document.getElementById('main').style.marginLeft = '250px';
    } else if (this.showNavtext === true) {
      document.getElementById('sidenav').style.width = '90px';
      document.getElementById('main').style.marginLeft = '90px';
    }
    // Smooth timeout van text
    setTimeout(() => {
      this.showNavtext = !this.showNavtext;
    }, 400);
  };
}
