import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

import { ErrorService } from './error.service';
import { NotificationService } from './notification.service';

// import { LoggingService } from './services/logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  // if the current solution gives problems, with  using the constructor, we can go back to using the injector (private injector:Injector)
  constructor(private notifier: NotificationService, private errorService: ErrorService) {}

  handleError(error: Error | HttpErrorResponse) {
    let message;
    let stackTrace;

    if (error instanceof Error) {
      // Client Error
      message = this.errorService.getClientMessage(error);
      stackTrace = this.errorService.getClientStack(error);
    } else {
      // Server Error
      message = this.errorService.getServerMessage(error);
      stackTrace = this.errorService.getServerStack(error);
    }

    console.log('error');

    // Log to notification center
    this.errorService.addError(error);

    // Feedback to user
    this.notifier.showError(message);

    // Always log errors
    // logger.logError(message, stackTrace);
    console.error(error);
  }
}
