interface IUrlPerEnvironment {
  localUrl: string;
  stagingUrl: string;
  productionUrl: string;
}

interface IApiConfig extends IUrlPerEnvironment {
  defaultAuthScope: string;
}

export const redirectUrls = {
  localUrl: 'http://localhost:4200/login',
  stagingUrl: 'https://trading-stage.nieuwestroom.nl/login',
  productionUrl: 'https://trading.nieuwestroom.nl/login',
} as IUrlPerEnvironment;

export const operationsApi = {
  localUrl: 'https://localhost:44323',
  stagingUrl: 'https://api-stage.nieuwestroom.nl',
  productionUrl: 'https://api.nieuwestroom.nl',
  defaultAuthScope: 'https://nieuwestroomapiservice-staging.azurewebsites.net/user_impersonation',
} as IApiConfig;

export const tradingApi = {
  localUrl: 'https://localhost:52462',
  stagingUrl: 'https://minimalapi-stage.nieuwestroom.nl/trading',
  productionUrl: 'https://minimalapi.nieuwestroom.nl/trading',
  defaultAuthScope: 'api://minimalapi/.default',
} as IApiConfig;

export const tradingForecastUi = {
  localUrl: 'https://localhost:60747',
  stagingUrl: 'https://forecast-acc.nieuwestroom.nl/',
  productionUrl: 'https://forecast.nieuwestroom.nl/',
} as IUrlPerEnvironment;

export const tradingDealsUi = {
  localUrl: 'https://localhost:60747',
  stagingUrl: 'https://deals-acc.nieuwestroom.nl/',
  productionUrl: 'https://deals.nieuwestroom.nl/',
} as IUrlPerEnvironment;

export const protectedResourceMap = new Map([
  ['https://graph.microsoft-ppe.com/v1.0/me', ['user.read']],

  [operationsApi.localUrl, [operationsApi.defaultAuthScope]],
  [operationsApi.stagingUrl, [operationsApi.defaultAuthScope]],
  [operationsApi.productionUrl, [operationsApi.defaultAuthScope]],

  [tradingApi.localUrl, [tradingApi.defaultAuthScope]],
  [tradingApi.stagingUrl, [tradingApi.defaultAuthScope]],
  [tradingApi.productionUrl, [tradingApi.defaultAuthScope]],
]);

const isProduction = window.location.href.startsWith('https://trading.nieuwestroom.nl');
const isStaging = window.location.href.startsWith('https://trading-stage.nieuwestroom.nl');

export const environment = {
  production: isProduction,

  msal: {
    clientId: '121623cd-fdc8-4272-9206-8e07bf6b1742',
    authority: 'https://login.microsoftonline.com/b65f2e69-e0ed-4835-896a-2e51e05f5da2',
    protectedResourceMap,
    scopes: ['user.read'],
  },

  redirectUrl: isProduction ? redirectUrls.productionUrl : isStaging ? redirectUrls.stagingUrl : redirectUrls.localUrl,

  getOperationsApiUrl: () =>
    isProduction ? operationsApi.productionUrl : localStorage['target-enviroment'] === 'local' ? operationsApi.localUrl : operationsApi.stagingUrl,

  getTradingApiUrl: () =>
    isProduction ? tradingApi.productionUrl : localStorage['target-enviroment'] === 'local' ? tradingApi.localUrl : tradingApi.stagingUrl,

  getTradingForecastUiUrl: () =>
    isProduction
      ? tradingForecastUi.productionUrl
      : localStorage['target-enviroment'] === 'local'
      ? tradingForecastUi.localUrl
      : tradingForecastUi.stagingUrl,

  getTradingDealsUiUrl: () =>
    isProduction ? tradingDealsUi.productionUrl : localStorage['target-enviroment'] === 'local' ? tradingDealsUi.localUrl : tradingDealsUi.stagingUrl,
};
