import { ChartModule } from 'angular-highcharts';
import { NgxPaginationModule } from 'ngx-pagination';

import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '@environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Components/login.component';
import { MinimalApiTestComponent } from './Components/minimal-api-test/minimal-api-test.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { NotificationCenterComponent } from './Components/notification-center/notification-center.component';
import { HomeDashboardComponent } from './Dashboards/home-dashboard/home-dashboard.component';
import { BlobErrorHttpInterceptor } from './helpers/BlobErrorHttpInterceptor';
import { ApiModule as OperationsApiModule, Configuration as OperationsApiConfiguration } from './modules/operations-api';
import { SharedModule } from './modules/shared/shared.module';
import { ApiModule as TradingApiModule, Configuration as TradingApiConfiguration } from './modules/trading-api';
import { TradingDealsUiConfiguration } from './modules/trading/classes/trading.dealsui.configuration';
import { TradingForecastUiConfiguration } from './modules/trading/classes/trading.forecastui.configuration';
import { GlobalErrorHandler } from './services/global-error-handler';
import { HttpErrorInterceptor } from './services/http-error.interceptor';

export const MY_FORMATS = {
  parse: {
    dateInput: ['DD-MM-YYYY'],
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const loggerCallback = (logLevel: LogLevel, message: string) => !environment.production; //&& console.log(message);

const msalInstanceFactory = () =>
  new PublicClientApplication({
    auth: {
      clientId: environment.msal.clientId,
      authority: environment.msal.authority,
      navigateToLoginRequestUrl: true,
      redirectUri: environment.redirectUrl,
      postLogoutRedirectUri: environment.redirectUrl,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: environment.production ? LogLevel.Error : LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });

const msalInterceptorConfigFactory = () => ({
  interactionType: InteractionType.Redirect,
  protectedResourceMap: environment.msal.protectedResourceMap,
});

const msalGuardConfigFactory = () => ({
  interactionType: InteractionType.Redirect,
  authRequest: {
    scope: environment.msal.scopes,
  },
});

@NgModule({
  declarations: [AppComponent, HomeDashboardComponent, LoginComponent, NotificationCenterComponent, MinimalApiTestComponent, NotFoundComponent],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    OperationsApiModule,
    TradingApiModule,
    HttpClientModule,
    FormsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatPaginatorModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatInputModule,
    MatTooltipModule,
    MatTableModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ChartModule,
    OverlayModule,
    MsalModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: OperationsApiConfiguration,
      useFactory: () =>
        new OperationsApiConfiguration({
          basePath: environment.getOperationsApiUrl(),
          withCredentials: false,
        }),
    },
    {
      provide: TradingApiConfiguration,
      useFactory: () =>
        new TradingApiConfiguration({
          basePath: environment.getTradingApiUrl(),
          withCredentials: false,
        }),
    },
    {
      provide: TradingForecastUiConfiguration,
      useFactory: () =>
        new TradingForecastUiConfiguration({
          basePath: environment.getTradingForecastUiUrl(),
        }),
    },
    {
      provide: TradingDealsUiConfiguration,
      useFactory: () =>
        new TradingDealsUiConfiguration({
          basePath: environment.getTradingDealsUiUrl(),
        }),
    },
  ],

  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
